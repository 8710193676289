import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import * as context from 'api/base';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';

const GoogleTags = () => {
	const channelSEO = useSelector(({ fuse }) => fuse.form.channelSettings?.value?.channelSEO) ?? [];

	function categorize(string) {
		if (string.includes('Tag-Meta-name')) {
			return 'meta-name';
		}
		if (string.includes('Tag-Meta-property')) {
			return 'meta-property';
		}
		if (string.includes('Tag-Title')) {
			return 'tag-title';
		}
		if (string.includes('Tag-Meta-http-equiv')) {
			return 'meta-http';
		}
		if (string.includes('Tag-Meta-charset')) {
			return 'meta-charset';
		}
		if (string.includes('Tag-Google-Tag-Manager')) {
			return 'gtm';
		}
		if (string.includes('Tag-GA4-Tag-Manager')) {
			return 'ga4tag';
		}
		if (string.includes('Tag-facebook-pixel-script-id')) {
			return 'fb-script-id';
		}
		if (string.includes('Tag-meta-facebook-domain-validation')) {
			return 'fb-domain-validation';
		}
		if (string.includes('Tag-sharpspring-script-domain')) {
			return 'sp-script-domain';
		}
		if (string.includes('Tag-sharpspring-script-account')) {
			return 'sp-script-account';
		}
		return null;
	}

	const mapSEOTags = useCallback(() => {
		// Spreading the SEO settings from the Channel and splitting into categories
		let obj = {
			metaName: [],
			metaProperty: [],
			metaCharset: {
				content: 'utf-8'
			},
			metaHttp: {
				content: 'text/html'
			}
		};
		channelSEO?.forEach(tag => {
			const category = categorize(tag.tag);
			switch (category) {
				case 'meta-name':
					obj = {
						...obj,
						metaName: [...obj.metaName, { name: tag.tag.split('-')?.[3], content: tag.content }]
					};
					break;
				case 'meta-property':
					obj = {
						...obj,
						metaProperty: [...obj.metaProperty, { name: tag.tag.split('-')?.[3], content: tag.content }]
					};
					break;
				case 'tag-title':
					obj = { ...obj, title: { name: tag.tag, content: tag.content } };
					break;
				case 'meta-http':
					obj = { ...obj, metaHttp: { name: 'Content-Type', content: tag.content } };
					break;
				case 'meta-charset':
					obj = { ...obj, metaCharset: { name: tag.tag, content: tag.content } };
					break;
				case 'gtm':
					obj = { ...obj, gtm: { name: tag.tag, content: tag.content } };
					break;
				case 'ga4tag':
					obj = { ...obj, ga4tag: { name: tag.tag, content: tag.content } };
					break;
				case 'fb-script-id':
					obj = { ...obj, fbScripts: { ...obj.fbScripts, fbScriptId: tag.content } };
					break;
				case 'fb-domain-validation':
					obj = { ...obj, fbScripts: { ...obj.fbScripts, fbDomainValidation: tag.content } };
					break;
				case 'sp-script-domain':
					obj = { ...obj, fbScripts: { ...obj.fbScripts, spDomain: tag.content } };
					break;
				case 'sp-script-account':
					obj = { ...obj, fbScripts: { ...obj.fbScripts, spScriptAccount: tag.content } };
					break;
				default:
					null;
			}
		});
		return obj;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const trackButtonClickManually = buttonLabel => {
		try {
			const ga4Content = mapSEOTags()?.ga4tag?.content;

			// If Google Analytics tracking ID is not provided, do nothing
			if (!ga4Content) return;
			// If button label is not provided, do nothing
			if (!buttonLabel) return;

			const formattedButtonLabel = buttonLabel?.trim().replace(/-/g, '_');

			// Todo - Remove this console.log statement after testing
			console.log('Button Clicked: ', formattedButtonLabel);

			ReactGA.event('button_click', {
				category: 'User Interaction',
				action: 'Button Click',
				label: formattedButtonLabel // This uniquely identifies which button was clicked
			});
		} catch (error) {
			console.log('Error: ', error);
		}
	};

	useEffect(() => {
		// Initialize GTM if configured
		const gtmContent = mapSEOTags()?.gtm?.content;
		if (gtmContent) {
			TagManager.initialize({ gtmId: gtmContent });
		}

		// Initialize GA4 if configured
		const ga4Content = mapSEOTags()?.ga4tag?.content;
		if (ga4Content) {
			ReactGA.initialize(ga4Content);
		}

		const handleButtonClick = event => {
			const element = event.target;
			const menuItem = element.closest('.MuiListItem-root');
			const menuList = element.closest('.MuiList-root');

			let label;

			if (menuItem) {
				label = menuItem.getAttribute('data-ga-label') || menuItem.innerText.trim();
			} else if (menuList?.length) {
				label = menuList.getAttribute('data-ga-label') || menuList.innerText.trim();
			} else {
				label = element.getAttribute('data-ga-label');
			}

			if (label) {
				trackButtonClickManually(label);
			}

			if (menuItem || menuList?.length) {
				return false;
			}

			return false;
		};

		document.addEventListener('click', handleButtonClick, false);

		// Clean up listener on component unmount
		return () => {
			document.removeEventListener('click', handleButtonClick);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Scripts for the facebook tracking
	const pixelScript = scriptId => `
			!function(f,b,e,v,n,t,s)
			{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
			n.callMethod.apply(n,arguments):n.queue.push(arguments)};
			if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
			n.queue=[];t=b.createElement(e);t.async=!0;
			t.src=v;s=b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t,s)}(window, document,'script',
			'https://connect.facebook.net/en_US/fbevents.js');
			fbq('init', '${scriptId}');
			fbq('track', 'PageView');
	`;

	// No Scripts for the facebook tracking
	const metaScript = id => `
		<img height="1" width="1" style="display:none"
		src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1"
  		/>
	`;
	const sharpSpringScript = (domain, accountId) => `
		var _ss = _ss || [];
		_ss.push([
		"_setDomain",
		"https://${domain}/net",
		]);
		_ss.push(["_setAccount", "${accountId}"]);
		_ss.push(["_trackPageView"]);
		window._pa = window._pa || {}; 
		(function () {
		var ss = document.createElement("script");
		ss.type = "text/javascript";
		ss.async = true;
		ss.src =
			("https:" == document.location.protocol ? "https://" : "http://") +
			"${domain}/client/ss.js?ver=2.4.0";
		var scr = document.getElementsByTagName("script")[0];
		scr.parentNode.insertBefore(ss, scr);
		})();
	`;

	const triggerFacebookScripts = () => {
		const allScripts = document.getElementsByTagName('script');
		if (mapSEOTags()?.fbScripts?.fbScriptId) {
			// Scripting & appending the Facebook tracking scripts
			const pxScript = document.createElement('script');
			const myScript = pixelScript(mapSEOTags()?.fbScripts.fbScriptId);

			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < allScripts.length; i++) {
				if (allScripts[i].innerHTML === myScript) return;
				if (allScripts.length - 1 === i) {
					pxScript.innerHTML = myScript;
					document.head.appendChild(pxScript);
				}
			}
		}
	};

	const triggerFacebookNoScripts = () => {
		const allScripts = document.getElementsByTagName('script');
		if (mapSEOTags()?.fbScripts?.fbScriptId) {
			// Scripting & appending the Facebook tracking NoScripts
			const noScript = document.createElement('noscript');
			const myNoScript = metaScript(mapSEOTags()?.fbScripts.fbScriptId);

			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < allScripts.length; i++) {
				if (allScripts[i].innerHTML === myNoScript) return;
				if (allScripts.length - 1 === i) {
					noScript.innerHTML = myNoScript;
					document.head.appendChild(noScript);
				}
			}
		}
	};

	const triggerSharpSpringScript = () => {
		const allScripts = document.getElementsByTagName('script');
		// Scripting & appending the Sharp spring tracking scripts
		if (mapSEOTags()?.fbScripts?.spDomain && mapSEOTags()?.fbScripts?.spScriptAccount) {
			const spScript = sharpSpringScript(
				mapSEOTags()?.fbScripts?.spDomain,
				mapSEOTags()?.fbScripts?.spScriptAccount
			);
			const sharpSpringSpt = document.createElement('script');

			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < allScripts.length; i++) {
				if (allScripts[i].innerHTML === spScript) return;
				if (allScripts.length - 1 === i) {
					sharpSpringSpt.innerHTML = spScript;
					document.head.appendChild(sharpSpringSpt);
				}
			}
		}
	};

	useEffect(() => {
		triggerFacebookScripts();
		triggerFacebookNoScripts();
		triggerSharpSpringScript();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<HelmetProvider>
			<Helmet>
				<title>{mapSEOTags()?.title ? mapSEOTags()?.title.content : context?.getChannelHost()?.tabName}</title>
				{mapSEOTags()?.metaName.map(tag => (
					<meta key={tag} name={tag.name} content={tag.content} />
				))}
				{mapSEOTags()?.metaProperty.map(tag => (
					<meta key={tag} name={tag.name} content={tag.content} />
				))}
				<meta charSet={mapSEOTags()?.metaCharset?.content} />
				<meta httpEquiv="Content-Type" charSet={mapSEOTags()?.metaHttp?.content} />
				{!mapSEOTags()?.metaName?.some(tag => tag.name === 'viewport') ? (
					<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
				) : null}

				{mapSEOTags()?.fbScripts?.fbDomainValidation && (
					<meta name="facebook-domain-verification" content={mapSEOTags()?.fbScripts?.fbDomainValidation} />
				)}
			</Helmet>
		</HelmetProvider>
	);
};

export default GoogleTags;
