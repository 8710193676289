import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
	getDeclaration,
	createPolicySale,
	getChannelSettings,
	getChannelProducts,
	getAttachments,
	getTypes,
	getProductPriceNew,
	getPaymentStatus,
	approvePolicy,
	getRefererAgentData,
	getProductPayment,
	generateCheckout,
	agentLogin,
	searchPolicyForClaims,
	createClaims,
	addClaimsAttachments,
	storeDraftPolicy,
	approvePolicySale,
	rejectPolicySale,
	updatePolicySale,
	getCompanyDetails,
	quotePolicySale,
	searchLookups,
	getPoliciesDetails,
	addRiskAttributeAttachment,
	searchPolicyByIdentifiers,
	endorseQuotePolicySale,
	endorseIssuePolicySale,
	getEndorsementProductPriceNew,
	getDetailsForPaymentRetry,
	addPolicyDeclaration
} from './actions/formAction';

const formSlice = createSlice({
	name: 'form',
	initialState: {
		steps: [],
		formValues: {},
		declaration: {},
		quote: {},
		createPolicyResponse: {},
		createClaimsResponse: {},
		addClaimsAttachmentsResponse: {},
		paymentTries: 0,
		channelSettings: {},
		channelProducts: [],
		attachments: {},
		types: {},
		productPriceNew: {},
		reloadCount: 0,
		risks: [],
		factors: [],
		primaryMemFields: {},
		benFields: {},
		paymentStatus: {},
		approvePolicyResponse: [],
		initialApiCallsResponse: {},
		referenceTokenDetails: { token: null, agentDetails: {} },
		productPaymentResponse: {},
		generateCheckoutResponse: {},
		draftPolicy: {},
		agentLoginResponse: {},
		cookies: { enabled: false },
		policyForClaimsResponse: {},
		approvePolicySaleResponse: {},
		rejectPolicySaleResponse: {},
		updatePolicySaleResponse: {},
		companyDetailsResponse: {},
		mySite: {},
		quotePolicyResponse: {},
		searchLookupResponse: {},
		policiesDetailsResponse: {},
		riskAttributeAttachmentResponse: {},
		searchPolicyResponse: {},
		failedAgentTokens: {},
		retryPaymentResponse: {},
		policyDeclarationResponse: {}
	},
	reducers: {
		saveSteps: (state, action) => {
			state.steps = [...action.payload];
		},
		saveForm: (state, action) => {
			state.formValues = { ...action.payload };
		},
		setReloadcount: (state, action) => {
			state.reloadCount = action.payload;
		},
		clearQuote: (state, action) => {
			state.quote = { loading: true };
		},
		clearPolicyDetails: (state, action) => {
			state.createPolicyResponse = {};
			state.generateCheckoutResponse = {};
			state.paymentStatus = {};
			state.approvePolicyResponse = [];
			state.policiesDetailsResponse = {};
		},
		clearForm: (state, action) => {
			state.formValues = {};
			state.quote = {};
			state.declaration = {};
			state.createPolicyResponse = {};
			state.steps = [];
			state.submitExternalResponse = null;
			state.paymentTries = 0;
			state.productPriceNew = {};
			state.attachments = {};
			state.paymentStatus = {};
			state.approvePolicyResponse = [];
			state.productPaymentResponse = {};
			state.generateCheckoutResponse = {};
			state.draftPolicy = {};
			state.createClaimsResponse = {};
			state.addClaimsAttachmentsResponse = {};
			state.approvePolicySaleResponse = {};
			state.rejectPolicySaleResponse = {};
			state.updatePolicySaleResponse = {};
			state.quotePolicyResponse = {};
			state.searchLookupResponse = {};
			state.riskAttributeAttachmentResponse = {};
			state.searchPolicyResponse = {};
			state.retryPaymentResponse = {};
			state.policyDeclarationResponse = {};

			// Setting the default values for payload properties
			const {
				canClearRisk = true,
				canClearFactor = true,
				canClearPrimaryMemFields = true,
				canClearBenFields = true,
				canClearInitalApiCallsResponse = true
			} = action.payload || {};

			// Clearing the states based on the payload properties
			if (canClearRisk) {
				state.risks = [];
			}

			if (canClearFactor) {
				state.factors = [];
			}

			if (canClearPrimaryMemFields) {
				state.primaryMemFields = {};
			}

			if (canClearBenFields) {
				state.benFields = {};
			}

			if (canClearInitalApiCallsResponse) {
				state.initialApiCallsResponse = {};
			}
		},
		clearFormForNewProduct: state => {
			state.formValues = {};
			state.declaration = {};
			state.createPolicyResponse = {};
			state.productPriceNew = {};
			state.attachments = {};
			state.risks = [];
			state.factors = [];
			state.paymentStatus = {};
			state.initialApiCallsResponse = {};
			state.approvePolicyResponse = [];
			state.productPaymentResponse = {};
			state.generateCheckoutResponse = {};
		},
		clearLogging: (state, action) => {
			state.agentLoginResponse = {};
		},
		resetPayment: (state, action) => {
			state.createPolicyResponse = {};
			state.paymentTries = 0;
			state.paymentStatus = {};
			state.approvePolicyResponse = [];
		},
		retryPayment: (state, action) => {
			state.paymentTries = action.payload;
		},
		retryPaymentConfirmation: (state, action) => {
			state.paymentStatus = {};
			state.approvePolicyResponse = [];
		},
		clearInitSettings: (state, action) => {
			state.channelSettings = {};
			state.channelProducts = [];
		},
		setFactors: (state, action) => {
			state.factors = { ...action.payload };
		},
		setRisks: (state, action) => {
			state.risks = [...action.payload];
		},
		setPMFields: (state, action) => {
			state.primaryMemFields = { ...action.payload };
		},
		setBenFields: (state, action) => {
			state.benFields = { ...action.payload };
		},
		setInitialApiCallsResponse: (state, action) => {
			state.initialApiCallsResponse = { ...action.payload };
		},
		setReferenceToken: (state, action) => {
			if (action.payload?.token) {
				state.referenceTokenDetails = {
					...state.referenceTokenDetails,
					token: action.payload.token
				};
			}
		},
		resumePolicyResponse: (state, action) => {
			state.draftPolicy = {
				...state.draftPolicy,
				...action.payload
			};
		},
		clearProductPriceNew: (state, action) => {
			state.productPriceNew = {};
		},
		saveCookies: (state, action) => {
			state.cookies = action.payload;
		},
		saveDraftPolicyCancelledStatus: (state, action) => {
			state.draftPolicy = { ...action.payload };
		},
		setMySite: (state, action) => {
			state.mySite = { ...action.payload };
		},
		clearQuotePolicyResponse: (state, action) => {
			state.quotePolicyResponse = {};
		},
		setFailAgentTokens: (state, action) => {
			state.failedAgentTokens = action.payload;
		},
		clearSearchPolicyResponse: (state, action) => {
			state.searchPolicyResponse = {};
		}
	},
	extraReducers: builder => {
		// Add reducers for additional action types here, and handle loading state as needed

		builder.addCase(getChannelSettings.pending, (state, action) => {
			state.channelSettings = { status: 'pending' };
		});
		builder.addCase(getChannelSettings.fulfilled, (state, action) => {
			state.channelSettings = { value: { ...action.payload }, status: 'success', loading: false };
		});
		builder.addCase(getChannelSettings.rejected, (state, action) => {
			state.channelSettings = { status: 'failed' };
		});

		builder.addCase(getChannelProducts.pending, (state, action) => {
			state.channelProducts = { status: 'pending' };
		});
		builder.addCase(getChannelProducts.fulfilled, (state, action) => {
			state.channelProducts = {
				value: [...action.payload],
				status: 'success',
				loading: false
			};
		});
		builder.addCase(getChannelProducts.rejected, (state, action) => {
			state.channelProducts = { status: 'failed', loading: false };
		});

		builder.addCase(getDeclaration.pending, (state, action) => {
			state.declaration = { ...state.declaration, loading: true, status: 'pending' };
		});
		builder.addCase(getDeclaration.fulfilled, (state, action) => {
			state.declaration = {
				value: [...action.payload],
				loading: false,
				status: 'fulfilled'
			};
		});
		builder.addCase(getDeclaration.rejected, (state, action) => {
			state.declaration = { loading: false, status: 'failed' };
		});

		builder.addCase(createPolicySale.pending, (state, action) => {
			state.createPolicyResponse = { ...state.createPolicyResponse, loading: true, status: 'pending' };
		});
		builder.addCase(createPolicySale.fulfilled, (state, action) => {
			state.createPolicyResponse = {
				...action.payload,
				request: action.meta.arg,
				loading: false,
				status: 'fulfilled'
			};
		});
		builder.addCase(createPolicySale.rejected, (state, action) => {
			state.createPolicyResponse = { loading: false, status: 'failed' };
		});

		builder.addCase(quotePolicySale.pending, (state, action) => {
			state.quotePolicyResponse = { ...state.quotePolicyResponse, loading: true, status: 'pending' };
		});
		builder.addCase(quotePolicySale.fulfilled, (state, action) => {
			state.quotePolicyResponse = {
				...action.payload,
				request: action.meta.arg,
				loading: false,
				status: 'fulfilled'
			};
		});
		builder.addCase(quotePolicySale.rejected, (state, action) => {
			state.quotePolicyResponse = { loading: false, status: 'failed' };
		});

		builder.addCase(getAttachments.pending, (state, action) => {
			state.attachments = { ...state.attachments, status: 'pending' };
		});
		builder.addCase(getAttachments.fulfilled, (state, action) => {
			state.attachments = { ...action.payload.attachments, status: 'success' };
		});
		builder.addCase(getAttachments.rejected, (state, action) => {
			state.attachments = { ...state.attachments, status: 'failed' };
			toast.error('Failed to load attachments', { autoClose: 4000 });
		});

		builder.addCase(getTypes.pending, (state, action) => {
			state.types = { ...state.types, loading: true };
		});
		builder.addCase(getTypes.fulfilled, (state, action) => {
			state.types = { value: { ...action.payload }, loading: false };
		});

		builder.addCase(getProductPriceNew.pending, (state, action) => {
			state.productPriceNew = { ...state.productPriceNew, status: 'pending' };
		});
		builder.addCase(getProductPriceNew.fulfilled, (state, action) => {
			state.productPriceNew = { value: { ...action.payload }, status: 'success' };
		});
		builder.addCase(getProductPriceNew.rejected, (state, action) => {
			state.productPriceNew = { ...state.productPriceNew, status: 'failed' };
		});

		builder.addCase(getPaymentStatus.pending, (state, action) => {
			state.paymentStatus = { ...state.paymentStatus, status: 'pending' };
		});
		builder.addCase(getPaymentStatus.fulfilled, (state, action) => {
			if (action.payload?.genericCode?.includes('200')) {
				state.paymentStatus = { value: { ...action.payload }, status: 'success', pStatus: 'success' };
			} else if (action.payload?.genericCode?.includes('100.000')) {
				state.paymentStatus = { value: { ...action.payload }, status: 'success', pStatus: 'success' };
			} else if (action.payload?.genericCode?.includes('000.000')) {
				state.paymentStatus = { value: { ...action.payload }, status: 'success', pStatus: 'retry' };
			} else {
				state.paymentStatus = { value: { ...action.payload }, status: 'success', pStatus: 'failed' };
			}
		});
		builder.addCase(getPaymentStatus.rejected, (state, action) => {
			state.paymentStatus = { ...state.paymentStatus, status: 'failed' };
		});

		builder.addCase(approvePolicy.pending, (state, action) => {
			state.approvePolicyResponse = { ...state.approvePolicyResponse, status: 'pending' };
		});
		builder.addCase(approvePolicy.fulfilled, (state, action) => {
			state.approvePolicyResponse = { value: { ...action.payload }, status: 'success' };
		});
		builder.addCase(approvePolicy.rejected, (state, action) => {
			state.approvePolicyResponse = { ...state.approvePolicyResponse, status: 'failed' };
		});

		builder.addCase(getRefererAgentData.pending, (state, action) => {
			state.referenceTokenDetails = { ...state.referenceTokenDetails, status: 'pending' };
		});
		builder.addCase(getRefererAgentData.fulfilled, (state, action) => {
			state.referenceTokenDetails = {
				...state.referenceTokenDetails,
				agentDetails: { ...action.payload },
				status: 'success'
			};
		});
		builder.addCase(getRefererAgentData.rejected, (state, action) => {
			const refTokenDetails = { ...state.referenceTokenDetails, agentDetails: {} };
			state.referenceTokenDetails = { ...refTokenDetails, status: 'failed' };
			toast.error('Invalid agent', { autoClose: 4000 });
		});

		builder.addCase(getProductPayment.pending, (state, action) => {
			state.productPaymentResponse = { ...state.productPaymentResponse, status: 'pending' };
		});
		builder.addCase(getProductPayment.fulfilled, (state, action) => {
			state.productPaymentResponse = { value: { ...action.payload }, status: 'success' };
		});
		builder.addCase(getProductPayment.rejected, (state, action) => {
			state.productPaymentResponse = { ...state.productPaymentResponse, status: 'failed' };
		});

		builder.addCase(generateCheckout.pending, (state, action) => {
			state.generateCheckoutResponse = { ...state.generateCheckoutResponse, status: 'pending' };
		});
		builder.addCase(generateCheckout.fulfilled, (state, action) => {
			state.generateCheckoutResponse = { value: { ...action.payload }, status: 'success' };
		});
		builder.addCase(generateCheckout.rejected, (state, action) => {
			state.generateCheckoutResponse = { ...state.generateCheckoutResponse, status: 'failed' };
		});

		builder.addCase(agentLogin.pending, (state, action) => {
			state.agentLoginResponse = { ...state.agentLoginResponse, status: 'pending' };
		});
		builder.addCase(agentLogin.fulfilled, (state, action) => {
			state.agentLoginResponse = { value: { ...action.payload }, status: 'success' };
		});
		builder.addCase(agentLogin.rejected, (state, action) => {
			state.agentLoginResponse = { ...state.agentLoginResponse, status: 'failed' };
		});

		builder.addCase(searchPolicyForClaims.pending, (state, action) => {
			state.policyForClaimsResponse = { ...state.policyForClaimsResponse, status: 'pending' };
		});
		builder.addCase(searchPolicyForClaims.fulfilled, (state, action) => {
			state.policyForClaimsResponse = { value: { ...action.payload }, status: 'success' };
		});
		builder.addCase(searchPolicyForClaims.rejected, (state, action) => {
			state.policyForClaimsResponse = { ...state.policyForClaimsResponse, status: 'failed' };
		});

		builder.addCase(createClaims.pending, (state, action) => {
			state.createClaimsResponse = { ...state.createClaimsResponse, loading: true, status: 'pending' };
		});
		builder.addCase(createClaims.fulfilled, (state, action) => {
			state.createClaimsResponse = {
				...action.payload,
				request: action.meta.arg,
				loading: false,
				status: 'fulfilled'
			};
		});
		builder.addCase(createClaims.rejected, (state, action) => {
			state.createClaimsResponse = { loading: false, status: 'failed' };
		});

		builder.addCase(addClaimsAttachments.pending, (state, action) => {
			state.addClaimsAttachmentsResponse = {
				...state.addClaimsAttachmentsResponse,
				loading: true,
				status: 'pending'
			};
		});
		builder.addCase(addClaimsAttachments.fulfilled, (state, action) => {
			state.addClaimsAttachmentsResponse = {
				...action.payload,
				loading: false,
				status: 'fulfilled'
			};
		});
		builder.addCase(addClaimsAttachments.rejected, (state, action) => {
			state.addClaimsAttachmentsResponse = { loading: false, status: 'failed' };
		});

		builder.addCase(storeDraftPolicy.pending, (state, action) => {
			state.draftPolicy = { ...state.draftPolicy, status: 'pending' };
		});
		builder.addCase(storeDraftPolicy.fulfilled, (state, action) => {
			if (action.payload?.status === 0) {
				state.draftPolicy = {
					value: { ...action.payload },
					requestBody: JSON.parse(action.meta.arg.draftDetails),
					draftType: action.meta.arg?.draftType,
					status: 'success'
				};
			} else {
				state.draftPolicy = { ...state.draftPolicy, status: 'failed' };
				toast.error('Failed to save policy draft', { autoClose: 4000 });
			}
		});
		builder.addCase(storeDraftPolicy.rejected, (state, action) => {
			state.draftPolicy = { ...state.draftPolicy, status: 'failed' };
			toast.error('Failed to save policy draft', { autoClose: 4000 });
		});

		builder.addCase(approvePolicySale.pending, (state, action) => {
			state.approvePolicySaleResponse = { ...state.approvePolicySaleResponse, status: 'pending' };
		});
		builder.addCase(approvePolicySale.fulfilled, (state, action) => {
			state.approvePolicySaleResponse = {
				status: 'fulfilled'
			};
		});
		builder.addCase(approvePolicySale.rejected, (state, action) => {
			state.approvePolicySaleResponse = { status: 'failed' };
		});

		builder.addCase(rejectPolicySale.pending, (state, action) => {
			state.rejectPolicySaleResponse = { ...state.rejectPolicySaleResponse, status: 'pending' };
		});
		builder.addCase(rejectPolicySale.fulfilled, (state, action) => {
			state.rejectPolicySaleResponse = {
				status: 'fulfilled'
			};
		});
		builder.addCase(rejectPolicySale.rejected, (state, action) => {
			state.rejectPolicySaleResponse = { status: 'failed' };
		});

		builder.addCase(updatePolicySale.pending, (state, action) => {
			state.createPolicyResponse = { ...state.createPolicyResponse, status: 'pending' };
		});
		builder.addCase(updatePolicySale.fulfilled, (state, action) => {
			state.createPolicyResponse = {
				...action.payload,
				request: { ...action.meta.arg, data: action.meta.arg.policySaleContract },
				loading: false,
				status: 'fulfilled',
				isUpdatePolicySale: true
			};
		});
		builder.addCase(updatePolicySale.rejected, (state, action) => {
			state.createPolicyResponse = { status: 'failed' };
		});

		builder.addCase(getCompanyDetails.pending, (state, action) => {
			state.companyDetailsResponse = { status: 'pending' };
		});
		builder.addCase(getCompanyDetails.fulfilled, (state, action) => {
			state.companyDetailsResponse = { value: { ...action.payload }, status: 'success', loading: false };
		});
		builder.addCase(getCompanyDetails.rejected, (state, action) => {
			state.companyDetailsResponse = { status: 'failed' };
		});

		builder.addCase(searchLookups.pending, (state, action) => {
			state.searchLookupResponse = { status: 'pending' };
		});
		builder.addCase(searchLookups.fulfilled, (state, action) => {
			state.searchLookupResponse = { value: { ...action.payload }, status: 'success', loading: false };
		});
		builder.addCase(searchLookups.rejected, (state, action) => {
			state.searchLookupResponse = { status: 'failed' };
		});

		builder.addCase(getPoliciesDetails.pending, (state, action) => {
			state.policiesDetailsResponse = { status: 'pending' };
		});
		builder.addCase(getPoliciesDetails.fulfilled, (state, action) => {
			state.policiesDetailsResponse = { value: { ...action.payload }, status: 'success', loading: false };
		});
		builder.addCase(getPoliciesDetails.rejected, (state, action) => {
			state.policiesDetailsResponse = { status: 'failed' };
		});

		builder.addCase(addRiskAttributeAttachment.pending, (state, action) => {
			state.riskAttributeAttachmentResponse = { status: 'pending' };
		});
		builder.addCase(addRiskAttributeAttachment.fulfilled, (state, action) => {
			state.riskAttributeAttachmentResponse = { status: 'success', loading: false };
		});
		builder.addCase(addRiskAttributeAttachment.rejected, (state, action) => {
			state.riskAttributeAttachmentResponse = { status: 'failed' };
		});

		builder.addCase(searchPolicyByIdentifiers.pending, (state, action) => {
			state.searchPolicyResponse = { status: 'pending' };
		});
		builder.addCase(searchPolicyByIdentifiers.fulfilled, (state, action) => {
			state.searchPolicyResponse = { value: { ...action.payload }, status: 'success', loading: false };
		});
		builder.addCase(searchPolicyByIdentifiers.rejected, (state, action) => {
			state.searchPolicyResponse = { status: 'failed' };
		});

		builder.addCase(endorseQuotePolicySale.pending, (state, action) => {
			state.createPolicyResponse = { ...state.createPolicyResponse, loading: true, status: 'pending' };
		});
		builder.addCase(endorseQuotePolicySale.fulfilled, (state, action) => {
			state.createPolicyResponse = {
				...action.payload,
				request: action.meta.arg,
				loading: false,
				status: 'fulfilled'
			};
		});
		builder.addCase(endorseQuotePolicySale.rejected, (state, action) => {
			state.createPolicyResponse = { loading: false, status: 'failed' };
		});

		builder.addCase(endorseIssuePolicySale.pending, (state, action) => {
			state.approvePolicyResponse = { ...state.approvePolicyResponse, status: 'pending' };
		});
		builder.addCase(endorseIssuePolicySale.fulfilled, (state, action) => {
			state.approvePolicyResponse = { value: { ...action.payload }, status: 'success' };
		});
		builder.addCase(endorseIssuePolicySale.rejected, (state, action) => {
			state.approvePolicyResponse = { ...state.approvePolicyResponse, status: 'failed' };
		});

		builder.addCase(getEndorsementProductPriceNew.pending, (state, action) => {
			state.productPriceNew = { ...state.productPriceNew, status: 'pending' };
		});
		builder.addCase(getEndorsementProductPriceNew.fulfilled, (state, action) => {
			state.productPriceNew = { value: { ...action.payload }, status: 'success' };
		});
		builder.addCase(getEndorsementProductPriceNew.rejected, (state, action) => {
			state.productPriceNew = { ...state.productPriceNew, status: 'failed' };
		});

		builder.addCase(getDetailsForPaymentRetry.pending, (state, action) => {
			state.retryPaymentResponse = { status: 'pending' };
		});
		builder.addCase(getDetailsForPaymentRetry.fulfilled, (state, action) => {
			state.retryPaymentResponse = { value: { ...action.payload }, request: action.meta.arg, status: 'success' };
		});
		builder.addCase(getDetailsForPaymentRetry.rejected, (state, action) => {
			state.retryPaymentResponse = { status: 'failed' };
		});

		builder.addCase(addPolicyDeclaration.pending, (state, action) => {
			state.policyDeclarationResponse = { status: 'pending' };
		});
		builder.addCase(addPolicyDeclaration.fulfilled, (state, action) => {
			state.policyDeclarationResponse = { status: 'success', loading: false };
		});
		builder.addCase(addPolicyDeclaration.rejected, (state, action) => {
			state.policyDeclarationResponse = { status: 'failed' };
		});
	}
});

export const {
	saveSteps,
	saveForm,
	setReloadcount,
	clearForm,
	clearInitForm,
	clearFormForNewProduct,
	clearLogging,
	resetPayment,
	retryPayment,
	retryPaymentConfirmation,
	clearQuote,
	clearInitSettings,
	setRisks,
	setFactors,
	setRisksInfo,
	setBenFields,
	setPMFields,
	clearPolicyDetails,
	setInitialApiCallsResponse,
	setReferenceToken,
	resumePolicyResponse,
	saveDraftPolicyCancelledStatus,
	clearProductPriceNew,
	saveCookies,
	setMySite,
	clearQuotePolicyResponse,
	setFailAgentTokens,
	clearSearchPolicyResponse
} = formSlice.actions;

export default formSlice.reducer;
